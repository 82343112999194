body,
html {
  background-color: black;
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
  height: 100%;
}

body {
  cursor: none;
  margin: 0;
  overflow: hidden;
}

main {
  height: 100%;
}
