.slideshow {
  color: White;
  height: 100%;
  position: relative;
  width: 100%;

  --slideshow__duration--fading: 300ms;
}

.slideshow--debug {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.slideshow:not(.slideshow--debug) .slideshow__item {
  position: absolute;
}

.slideshow__item {
  height: 100%;
  left: 0;
  opacity: 1;
  top: 0;
  transition: opacity var(--slideshow__duration--fading);
  width: 100%;
}

.slideshow--disable-fading .slideshow__item--faded {
  display: none;
}

.slideshow__item--faded {
  opacity: 0;
}

.slideshow__asset {
  background-color: Black;
  height: 100%;
  object-fit: cover;
  user-select: none;
  width: 100%;
}

.slideshow__asset--iframe {
  border: 0;
  pointer-events: none;
}

/* .slideshow__asset-debug {
  background-color: red;
  height: 200px;
  left: calc(50% - 100px);
  position: absolute;
  top: calc(50% - 100px);
  width: 200px;
} */
