.app {
  height: 100%;
  width: 100%;
}

.app__version {
  bottom: 0.2em;
  color: White;
  opacity: 0.4;
  font-size: smaller;
  position: fixed;
  right: 0.2em;
  text-align: right;
  text-shadow: 0 0 2px Black;
}
